<template>

    <div id="map">
        <v-btn
            tile
            v-if="showListButton && !drawer"
            color="blue-grey"
            class="white--text btn-drawer-list rounded-r-lg"
            :style="{left: (drawer ? '256px' : '0')}"
            @click.stop="toggleDrawer()"
            >
            <v-icon
                right
                dark
                class="mx-0"
            >
                mdi-format-list-bulleted
            </v-icon>
        </v-btn>

        <v-navigation-drawer
            v-model="drawer"
            absolute
            :temporary="false"
            clipped
            hide-overlay
            v-on:transitionend="transitionEnd()"
            style="z-index:1001;overflow:visible;"
            >

                <v-btn
                    tile
                    v-if="drawer"
                    color="blue-grey"
                    class="white--text btn-drawer-list rounded-r-lg"
                    :style="{left: (drawer ? '256px' : '0')}"
                    @click.stop="drawer = !drawer"
                    >
                    <v-icon
                        right
                        dark
                    >
                        mdi-format-list-bulleted
                    </v-icon>
                </v-btn>

                <v-text-field
                    v-model="search"
                    :label="$t('search')"
                    class="mx-4"
                ></v-text-field>

                <v-data-table
                    :headers="headers"
                    :items="markers"
                    :search="search"
                    sort-by="projectName"
                    disable-pagination
                    hide-default-footer
                    hide-default-header
                    id="system-menu"
                    @current-items="getCurrentItems"
                    >
                        <template v-slot:item="data">
                            <tr :class="data.item.status > 1 ? 'error' : data.item.status > 0 ? 'warning' : ''" @click="selectSystem(data.item.id)">
                                <td class="text-start">{{ data.item.projectName }}</td>
                                <td class="text-start">{{ data.item.camNo }}</td>
                            </tr>
                        </template>
                </v-data-table>

                <div class="spinner"
                    v-if="spinner">
                </div>
            </v-navigation-drawer>

            <v-map ref="mappy" :zoom=3 :center="[43.551718, 10.034237]">
                <!-- default tile provider https://{s}.tile.osm.org/{z}/{x}/{y}.png -->
                <v-tilelayer url="https://tile.openstreetmap.de/{z}/{x}/{y}.png"></v-tilelayer>

                <div v-for="(marker, idx) in visibleMarkers"
                        :key="idx">
                    <v-marker :lat-lng="[marker.config.latitude, marker.config.longitude]"
                        :ref="'marker'+marker.id"
                        :id="'marker-'+marker.id"
                            @mouseover="setIsCursor(true)"
                            @mouseout="setIsCursor(false)">
                        <v-mapicon :iconSize="[30, 30]"
                                :iconUrl="marker.status > 1 ? '/marker_error.svg' : marker.status > 0 ? '/marker_warning.svg' : '/marker_info.svg'"></v-mapicon>

                        <v-popup :id="'popup-'+marker.id">
                            <SystemTile :type="marker.enabled"
                                :imageWidth="tileWidth"
                                :cameraNumber=parseInt(marker.camNo)
                                :computerName="marker.computerName"
                                :projectName="marker.projectName"
                                :client="marker.client"
                                :systemId="marker.id"
                                :timezone="marker.timezone"/>
                        </v-popup>
                    </v-marker>
                </div>
            </v-map>

    </div>

</template>


<script>
import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
} from 'vue2-leaflet'
import moment from 'moment-timezone'
import { rest } from '../_helpers'
import { isTouchDevice, healthCheck } from '../_helpers/misc'
import SystemTile from '@/components/SystemTile.vue'


export default {
    components: {
        SystemTile,
        'v-map': LMap,
        'v-tilelayer': LTileLayer,
        'v-marker': LMarker,
        'v-popup': LPopup,
        'v-mapicon': LIcon,
    },

    data() {
        return {
            spinner: true,
            drawer: true,
            showListButton: false,
            search: '',
            headers: [
                {
                    text: 'Project',
                    align: 'start',
                    sortable: true,
                    value: 'projectName',
                },
                {
                    text: 'Number',
                    align: 'start',
                    sortable: false,
                    value: 'camNo',
                },
            ],
            markers: [],
            visibleMarkers: [],
            isTouch: this.isTouchDevice(),
            isCursor: false,
            tileWidth: 13,

            selectedItem: 0,
        }
    },

    methods: {
        isUpToDate(item) {
            const lastImage = moment(item.lastImageDate)
            const imageDiff = moment().diff(lastImage, 'minutes')
            if (imageDiff >= 60) {
                return false
            }
            return true
        },

        getCurrentItems(currentMarkers) {
            this.visibleMarkers = currentMarkers
        },

        toggleDrawer() {
            this.drawer = !this.drawer
            this.showListButton = !this.showListButton
        },

        transitionEnd() {
            if (!this.drawer) {
                this.showListButton = true
            }
        },

        getSystemName(data) {
            return `${data.projectName} ${data.camNo}`
        },

        selectSystem(markerId) {
            const marker = this.$refs[`marker${markerId}`][0]
            marker.mapObject.openPopup()
        },

        getMarkers() {
            const payload = {
                attributes: {
                    include: [
                        ['computerData.computer.name', 'computerName'],
                        ['project.name', 'projectName'],
                        ['routerData.make', 'routerMake'],
                        ['housingData.make', 'housingMake'],
                        ['config.timezone', 'timezone'],
                    ],
                    exclude: [
                        'lens',
                        'camera',
                        'housing',
                        'control',
                        'switch',
                        'dryer',
                        'sim1',
                        'sim2',
                        'simWSwitch',
                        'panomax',
                        'archive',
                        'wiper',
                        'ups',
                        'watchdog',
                        'extHdd',
                        'olympusUsb',
                        'satellite',
                        'ftpTimeout',
                        'alertContacts',
                    ],
                },
                where: {
                    enabled: true,
                },
                limit: this.limit,
                offset: this.offset,
                order: [['projectName', 'ASC'], ['camNo', 'ASC']],
                includes: [
                    {
                        type: 'project',
                        attributes: ['name'],
                    },
                    {
                        type: 'config',
                        where: {
                            latitude: { $not: null },
                            longitude: { $not: null },
                        },
                        attributes: ['longitude', 'latitude', 'timezone'],
                    },
                    {
                        type: 'computerData',
                        attributes: [],
                    },
                    {
                        type: 'routerData',
                        attributes: [],
                    },
                    {
                        type: 'housingData',
                        attributes: [],
                    },
                    {
                        type: 'errorData',
                    },
                ],
            }

            rest.getRows('system', payload)
                .then(systems => {
                    systems.forEach(system => {
                        const { health, level } = healthCheck(system)
                        system.health = health
                        system.status = level
                    })
                    this.markers = systems
                })
                .finally(() => {
                    this.spinner = false
                })
        },

        isTouchDevice() {
            return isTouchDevice()
        },

        setIsCursor(cursor) {
            this.isCursor = cursor
        },
    },

    mounted() {
        this.getMarkers()
    },
}
</script>

<style lang="stylus">
    #map .leaflet-popup-content-wrapper
        border-radius 0

    #map .leaflet-popup-content
        margin 2px

</style>

<style lang="stylus" scoped>
    @media (pointer: coarse)
        .test
            display none

    @media (pointer: fine)
        .test
            display none

    #map
        height 100%
        position relative
        z-index 0

    #system-menu
        height calc(100% - 70px)
        overflow-y auto

    .btn-drawer-list
        position absolute
        left 0px
        top 80px
        z-index 1002
</style>
